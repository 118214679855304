<template>
	<div class="page">
		<div v-if="orderDetail.no&&orderState!==''">
			<div class="timeLine" v-if="orderState==='ing'">
				<div class="time">{{hour}}:{{minute>9?minute:'0'+minute}}:{{second>9?second:'0'+second}}</div>
				<div class="desc">
					<img class="descImg" src="../../assets/img/clock.png">
					<div class="descText" v-if="orderState==='ing'">剩余支付时间</div>
				</div>
			</div>
			<div class="orderState" v-else>
				<div v-if="orderState==='complete'">
					<img  class="orderStateImg" src="../../assets/img/paySuccess.png">
					<div class="orderStateText">支付成功</div>
				</div>
				<div v-if="orderState==='cancel'">
					<img  class="orderStateImg" src="../../assets/img/orderCancel.png">
					<div class="orderStateText">订单已取消</div>
				</div>
				<div v-if="orderState==='overtime'">
					<img  class="orderStateImg" src="../../assets/img/orderOvertime.png">
					<div class="orderStateText">已超时</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="orderState" style="background-color: #f9bd05;">
				<div class="orderStateText" style="line-height: 2.5rem;">正在获取支付数据...</div>
			</div>
		</div>
		<div style="background-color: #fff;padding:.32rem;">
			<div class="shopName">花生宠物</div>
			<div class="goodsLine">
				<img class="goodsImg" v-if="!$public.isNull(goodsDetail.bannerList)" :src="goodsDetail.bannerList[0].url">
				<img class="goodsImg" v-else src="../../assets/img/noPicture.png">
				<div class="goodsMsg">
					<div class="goodsName">
						<span>{{goodsDetail.insideName}}</span>
						<span>¥{{goodsDetail.petLevel.price}}</span>
					</div>
					<div class="goodsSku">
						<div class="color">{{goodsDetail.petColor}}</div>
						<div class="color">芯片：{{goodsDetail.epromNo}}</div>
					</div>
				</div>
			</div>
			<div class="orderMsgLine">
				<div class="label">订单号</div>
				<div class="value">{{orderDetail.no}}</div>
			</div>
			<div class="orderMsgLine">
				<div class="label">客户手机</div>
				<div class="value">{{orderDetail.user_info.user_mobile.substr(0,3)}}****{{orderDetail.user_info.user_mobile.substr(7,10)}}</div>
			</div>
			<!-- 订金订单 -->
			<div v-if="orderDetail.order_type_id===10">
				<div class="orderMsgLine">
					<div class="label">已付订金</div>
					<div class="value">¥ {{allDingjin}}</div>
				</div>
			</div>
			<!-- 追加订金 -->
			<!-- <div v-if="orderDetail.order_type_id===10&&allDingjin>0">
				<div class="orderMsgLine">
					<div class="label">已付订金</div>
					<div class="value">¥ {{allDingjin}}</div>
				</div>
				<div class="orderMsgLine">
					<div class="label">追加订金</div>
					<div class="value">¥ {{orderDetail.total_price}}</div>
				</div>
			</div> -->
			<div v-if="orderDetail.order_type_id===8">
				<div class="orderMsgLine">
					<div class="label">订金抵扣</div>
					<div class="value">¥ {{useDingjin}}</div>
				</div>
				<div class="orderMsgLine">
					<div class="label">商品总额</div>
					<div class="value">¥ {{orderDetail.order_item_list[0].original_price}}</div>
				</div>
				<div class="orderMsgLine">
					<div class="label">活动</div>
					<div class="value">{{orderDetail.order_item_list[0].activity_info.activity_name}}</div>
				</div>
				<div class="orderMsgLine">
					<div class="label">优惠金额</div>
					<div class="value">¥ {{$public.jian(orderDetail.order_item_list[0].original_price,orderDetail.order_item_list[0].price)}}</div>
				</div>
			</div>
			<div class="orderMsgLine" style="height: 1.6rem;">
				<div class="label">备注</div>
				<div class="value">
					<textarea v-if="orderState==='ing'" class="value" type="text" v-model="remark" rows="3" placeholder="选填，请预先沟通" style="text-align: right;outline:none;resize:none;border:0;padding-top:.3rem;line-height: .4rem;width:4rem;"/>
					<span v-else>{{remark}}</span>
				</div>
			</div>
			<div class="totalLine" v-if="orderState==='ing'">
				待支付：<span style="font-size: .26rem;color:#ff5701;">¥ {{$public.jian(orderDetail.total_price,useDingjin)||0}}</span>
			</div>
		</div>
		<div style="height: 2rem;"></div>
		<div class="submitBtn" v-if="this.orderState === 'ing'" @click="submit">立即支付</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				hour:0,
				minute:0,
				second:0,
				timer:null,
				
				orderState:'', // overtime cancel complete
				orderDetail:{
					order_item_list:[{
						activity_info:{},
						order_item_goods_list:[{
							custom_code:''
						}]
					}],
					user_info:{
						user_mobile:''
					}
				},
				goodsDetail:{
					petLevel:{
						price:0,
					}
				},
				allDingjin:0,
				useDingjin:0,
				
				uId:'',
				orderNum:'',
				remark:'',
			}
		},
		mounted(){
			this.uId = this.$public.queryString('uId')
			this.orderNum = this.$public.queryString('orderNum')
			
			this.getOrderDetail();
		},
		methods:{
			getOrderDetail(){
				let orderNum = this.orderNum
				let params = {
					id: orderNum,
				}
				this.$http.get(process.env.VUE_APP_Url_order + '/api/OffLineOrder/GetShowDJ', params).then(res => {
					if (res.code == 0) {
						if(this.$public.isNull(res.data.order_item_list[0].activity_info)){
							res.data.order_item_list[0].activity_info = []
						}
						this.orderDetail = res.data;
						this.getGoodsDetail(this.orderDetail.order_item_list[0].goods_id)
						this.remark = this.orderDetail.remark;
						this.useDingjin = 0;
						if(this.orderDetail.order_type_id===10){
							this.getAllDingjin(this.orderDetail.user_info.user_id, this.orderDetail.order_item_list[0].goods_id)
						}else if(this.orderDetail.order_type_id===8){
							if(this.orderDetail.state===3){
								this.useDingjin = this.$public.getMatchingItem(this.orderDetail.pay_info.pay_list,'pay_type',14).money||0
							}else{
								if(this.$public.isNull(this.orderDetail.djList)){
									this.orderDetail.djList = []
								}
								for(let i=0;i<this.orderDetail.djList.length;i++){
									this.useDingjin += this.orderDetail.djList[i].total_price;
								}
							}
						}
						if(this.orderDetail.state===3){
							this.orderState = 'complete'
						}else if(this.orderDetail.state===1||this.orderDetail.state===2){
							this.timer = setInterval(()=>{
								this.orderState = 'ing'
								let aa = this.$public.FAge(new Date(), this.orderDetail.create_time*1000+1800000)
								this.hour = aa[3]
								this.minute = aa[4]
								this.second = aa[5]
								if(this.hour>0||this.minute>0||this.second>0){
									console.log("时间在流逝")
								}else{
									this.orderState = 'overtime'
									clearInterval(this.timer)
								}
							},1000)
						}else{
							this.orderState = 'cancel'
						}
					}
				})
			},
			getGoodsDetail(petId){
				let params = {
					petId:petId,
				}
				this.$http.get(process.env.VUE_APP_Url_baseData + '/api/product/pet/detail/info', params).then(res => {
					if (res.code == 0) {
						let d = res.data
						this.goodsDetail = d;
					}
				})
			},
			getAllDingjin(user_id,pet_id){
				let params = {
					user_id:user_id,
					pet_id:pet_id,
				}
				this.$http.get(process.env.VUE_APP_Url_order + '/api/OffLineOrder/GetPetDJMoney', params).then(res => {
					if (res.code == 0) {
						this.allDingjin = res.data
					}
				})
			},
			submit(){
				let orderNum = this.orderNum
				let uId = this.uId
				if(this.$public.jian(this.orderDetail.total_price,this.useDingjin)<=0){
					// 此订单支付0元，是否确认付款
					// 找零金额200
					this.$dialog.confirm({
						title: '提示',
						message:'此订单支付0元，是否确认付款',
					}).then(()=>{
						this.$http.get(process.env.VUE_APP_Url_business + '/api/Pay/H5PaySuccess', {
							orderNum: orderNum,
						}).then(res => {
							if (res.code == 0) {
								this.$router.push({path:'/hsPet/payResult',query:{orderNo:orderNum,uId:uId}})
							}
						})
					})
				}else{
					let params = {
						orderNum: orderNum,
						completeUrl: process.env.VUE_APP_Url_M + "/#/hsPet/payResult?orderNo=" + orderNum + "&uId="+uId,
						remark: this.remark,
					}
					this.$http.get(process.env.VUE_APP_Url_business + '/api/Pay/H5PayOrder', params).then(res => {
						if (res.code == 0) {
							this.$toast({
								message: '跳转中',
								icon: 'info',
							});
							window.location.href=res.data;
						}
					})
				}
			},
		},
		beforeDestroy(){
			clearInterval(this.timer)
		},
	};
</script>
<style lang="scss" scoped>
.page{
	width:100%;height: 100%;box-sizing: border-box;overflow: auto;
	.timeLine{
		width:100%;height: 2.6rem;background-color: #f9bd05;
		.time{
			font-size: .56rem;font-weight: bold;color:#333;padding-top:.7rem;
		}
		.desc{
			display: flex;justify-content: center;padding-top:.2rem;
			.descImg{
				width:.32rem;height: .32rem;margin-right: .16rem;
			}
			.descText{
				font-size: .28rem;color:#333;
			}
		}
	}
	.orderState{
		width:100%;height: 2.6rem;
		.orderStateImg{
			width:1.12rem;height: 1.12rem;padding-top:.6rem;
		}
		.orderStateText{
			font-size: .28rem;color:#666;
		}
	}
	.shopName{
		font-size: .28rem;color:#333;display: flex;padding:0 0 .28rem 0;
	}
	.goodsLine{
		display: flex;border-bottom:0.02rem solid #f0f0f2;padding-bottom:.2rem;
		.goodsImg{
			width:1.56rem;height: 1.56rem;border-radius: .2rem;margin-right: .24rem;
		}
		.goodsMsg{
			width:5rem;
			.goodsName{
				font-size: .26rem;color:#333;display: flex;justify-content: space-between;padding:.1rem 0 .1rem;
			}
			.goodsSku{
				background-color: #f9f9fb;border-radius: .08rem;color:#999;width:3.2rem;text-align: left;line-height: .4rem;padding:.1rem .16rem;font-size: .24rem;
			}
		}
	}
	.orderMsgLine{
		display: flex;justify-content: space-between;font-size: .26rem;color:#333;border-bottom:0.02rem solid #f0f0f2;line-height: 1rem;
		.label{
			
		}
		.value{
			
		}
	}
	.totalLine{
		font-size: .26rem;color:#333;text-align: right;padding-top:.3rem;font-weight: bold;
	}
	
	.submitBtn{
		width:6.86rem;line-height: .92rem;background-color: #1b8dff;border-radius: .06rem;color:#fff;font-size: .28rem;position: fixed;bottom:.3rem;left:.3rem;
	}
}
</style>
